import React from 'react';

import styled from 'styled-components';

import { graphql, useStaticQuery } from 'gatsby';

import _ImageGallery from '../../components/ImageGallery/ImageGallery';
import Layout from '../../components/layout';

const ImageGallery = styled(_ImageGallery)`
    margin-top: 30px;
    margin-bottom: 60px;
`;

const query = graphql`
    {
        allFile(
            filter: { relativeDirectory: { eq: "gallery/wedding" } }
            sort: { fields: [birthTime], order: DESC }
        ) {
            edges {
                node {
                    dir
                    modifiedTime
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            src
                            aspectRatio
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

const GalleryPage = ({ location }) => {
    const data = useStaticQuery(query);
    const pictures = data.allFile.edges.map(
        edge => edge.node.childImageSharp.fluid
    );

    return (
        <Layout location={location}>
            <h1 className="major">Mariage</h1>
            <p>
                Je suis spécialisé dans le reportage de mariage, où
                l'authenticité est pour moi primordiale. Je privilégie donc la
                discrétion afin d'avoir des photos spontanées.
            </p>
            <p>
                Je vous propose aussi de réaliser une séance photo de couple le
                jour J pendant environ une demi-heure.
            </p>

            <ImageGallery pictures={pictures} />
        </Layout>
    );
};

export default GalleryPage;
